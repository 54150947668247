/* eslint-disable prefer-destructuring */

import { convertWpEmbedToOembed } from "@/utils/convertEmbed";

export function parseContent(html: string): string {
  let parsedHtml = cleanUnnecessarySpaces(html);
  const baseURL = process.env.WORDPRESS_BASE_ASSETS_URL;
  const cloudinaryBase = process.env.WORDPRESS_CLOUDINARY_ASSETS_BASE_URL;

  if (baseURL && cloudinaryBase) {
    const escapedBaseURL = baseURL.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

    const regex = new RegExp(
      `${escapedBaseURL}(\\d{4})\\/(\\d{2})\\/([^\\s]+?)(-\\d+x\\d+)?`,
      "g"
    );

    parsedHtml = parsedHtml.replace(regex, `${cloudinaryBase}$3`);
  }

  parsedHtml = decodeHtmlEntities(parsedHtml);

  // Remove dimensions from Cloudinary URLs
  // const cloudinaryRegex =
  //   /https:\/\/res\.cloudinary\.com\/[^/]+\/image\/upload\/[^/]+\/([^-\s]+)(-\d+x\d+)?/g;
  // parsedHtml = parsedHtml.replace(
  //   cloudinaryRegex,
  //   (_match, p1, _p2) =>
  //     `https://res.cloudinary.com/dv6hrcluu/image/upload/wordpress_assets/${p1}`
  // );
  // // should replace iem1dxsxtbg0f4zo3g0t-1-1024x512.jpg  with just iem1dxsxtbg0f4zo3g0t
  // const cloudinaryRegexRemoveVersion =
  //   /https:\/\/res\.cloudinary\.com\/dv6hrcluu\/image\/upload\/wordpress_assets\/([a-zA-Z0-9_-]+)-[0-9]+-[0-9]+x[0-9]+(\.[a-zA-Z0-9]+)?/g;

  // parsedHtml = parsedHtml.replace(
  //   cloudinaryRegexRemoveVersion,
  //   (_match, p1) =>
  //     `https://res.cloudinary.com/dv6hrcluu/image/upload/wordpress_assets/${p1}`
  // );

  parsedHtml = parseVideos(parsedHtml);

  return parsedHtml;
}

function parseVideos(html: string): string {
  let parsedHtml = convertWpEmbedToOembed(html);

  const youtubeRegex =
    /https:\/\/www\.youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]+)/g;
  const youtubeRegex2 = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]+)/g;
  const vimeoRegex = /https:\/\/player\.vimeo\.com\/video\/(\d+)/g;
  const vimeoRegex2 = /https:\/\/vimeo\.com\/(\d+)/g;
  const tiktokRegex = /https:\/\/www\.tiktok\.com\/@(.*?)\/video\/(\d+)/g;
  const twitterRegex =
    /https:\/\/twitter\.com\/([a-zA-Z0-9_]+)\/status\/(\d+)/g;
  const figureYouTubeRegex =
    /<figure class="media">https:\/\/(?:www\.youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)<\/figure>/g;
  const figureVimeoRegex =
    /<figure class="media">https:\/\/vimeo\.com\/(\d+)<\/figure>/g;

  // Replace <oembed> tag with the captured URL
  const oembedRegex = /<oembed[^>]+url="([^"]+)"[^>]*><\/oembed>/gi;
  parsedHtml = parsedHtml.replace(oembedRegex, (match, url) => url);

  // Check if the video URL is already in an iframe
  const iframeRegex = /<iframe[^>]+src="([^"]+)"[^>]*><\/iframe>/g;

  parsedHtml = parsedHtml.replace(youtubeRegex, (match, videoId) => {
    const isInsideAnchorTag = new RegExp(
      `<a[^>]*href=["']${match}["'][^>]*>`,
      "i"
    ).test(parsedHtml);
    if (!iframeRegex.test(parsedHtml) && !isInsideAnchorTag) {
      return embedYouTube(videoId);
    }
    return match;
  });

  parsedHtml = parsedHtml.replace(youtubeRegex2, (match, videoId) => {
    const isInsideAnchorTag = new RegExp(
      `<a[^>]*href=["']${match}["'][^>]*>`,
      "i"
    ).test(parsedHtml);
    if (!iframeRegex.test(parsedHtml) && !isInsideAnchorTag) {
      return embedYouTube(videoId);
    }
    return match;
  });

  parsedHtml = parsedHtml.replace(figureYouTubeRegex, (match, videoId) =>
    embedYouTube(videoId)
  );

  parsedHtml = parsedHtml.replace(vimeoRegex, (match, videoId) => {
    const isInsideAnchorTag = new RegExp(
      `<a[^>]*href=["']${match}["'][^>]*>`,
      "i"
    ).test(parsedHtml);
    if (!iframeRegex.test(parsedHtml) && !isInsideAnchorTag) {
      return embedVimeo(videoId);
    }
    return match;
  });

  parsedHtml = parsedHtml.replace(vimeoRegex2, (match, videoId) => {
    const convertedUrl = convertVimeoUrl(match);
    const isInsideAnchorTag = new RegExp(
      `<a[^>]*href=["']${convertedUrl}["'][^>]*>`,
      "i"
    ).test(parsedHtml);
    if (!iframeRegex.test(parsedHtml) && !isInsideAnchorTag) {
      return embedVimeo(videoId);
    }
    return convertedUrl;
  });

  parsedHtml = parsedHtml.replace(figureVimeoRegex, (match, videoId) =>
    embedVimeo(videoId)
  );

  parsedHtml = parsedHtml.replace(tiktokRegex, (match, username, videoId) => {
    const isInsideAnchorTag = new RegExp(
      `<a[^>]*href=["']${match}["'][^>]*>`,
      "i"
    ).test(parsedHtml);
    if (!iframeRegex.test(parsedHtml) && !isInsideAnchorTag) {
      return embedTikTok(username, videoId);
    }
    return match;
  });

  parsedHtml = parsedHtml.replace(twitterRegex, (match, username, tweetId) => {
    const isInsideAnchorTag = new RegExp(
      `<a[^>]*href=["']${match}["'][^>]*>`,
      "i"
    ).test(parsedHtml);
    if (!iframeRegex.test(parsedHtml) && !isInsideAnchorTag) {
      return embedTwitter(username, tweetId);
    }
    return match;
  });

  return parsedHtml;
}

function convertVimeoUrl(url: string): string {
  const vimeoRegex2 = /https:\/\/vimeo\.com\/(\d+)/;
  return url.replace(vimeoRegex2, "https://player.vimeo.com/video/$1");
}

function embedYouTube(videoId: string): string {
  return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
}

function embedVimeo(videoId: string): string {
  return `<iframe src="https://player.vimeo.com/video/${videoId}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
}

function embedTikTok(username: string, videoId: string): string {
  return `<iframe src="https://www.tiktok.com/embed/v2/${videoId}" width="325" height="750" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
}

function embedTwitter(username: string, tweetId: string): string {
  return `<blockquote class="twitter-tweet"><a href="https://twitter.com/${username}/status/${tweetId}"></a></blockquote>`;
}

const cleanUnnecessarySpaces = (htmlContent: string): string => {
  // return htmlContent;
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Get all p elements in the document
  const pElements = doc.querySelectorAll("p");

  pElements.forEach((p) => {
    // Check if the p element has only one child and that child is the specific span
    if (p.children.length === 1) {
      const child = p.children[0];
      if (
        (child?.tagName === "SPAN" &&
          /^s\d+$/.test(child?.className) && // Check for class name starting with 's' followed by digits
          child?.innerHTML.trim() === "<br>") ||
        (child?.tagName === "BR" &&
          child?.parentElement?.innerHTML === "<br>") ||
        (child?.tagName === "SPAN" && child?.innerHTML.trim() === "<br>")
      ) {
        // Remove the p element
        p.parentElement?.removeChild(p);
      }
    }
    // Check if the p element has two children: a span and a br
    if (p.children.length === 2) {
      const firstChild = p.children[0];
      const secondChild = p.children[1];

      if (
        firstChild?.tagName === "SPAN" &&
        /^s\d+$/.test(firstChild?.className) && // Check for class name starting with 's' followed by digits
        secondChild?.tagName === "BR" &&
        firstChild?.innerHTML.trim() === "" // Ensure the span is empty
      ) {
        // Remove the p element
        p.parentElement?.removeChild(p);
      }
    }
  });

  return doc.body.innerHTML;
};

const decodeHtmlEntities = (html: string): string =>
  html.replace(/&([a-zA-Z]+|#[0-9]+|#x[0-9a-fA-F]+);/g, (match, entity) => {
    if (entity.startsWith("#x")) {
      // Hexadecimal numeric entity
      return String.fromCharCode(parseInt(entity.substring(2), 16));
    }
    if (entity.startsWith("#")) {
      // Decimal numeric entity
      return String.fromCharCode(parseInt(entity.substring(1), 10));
    }
    // Named entity
    const entities: Record<string, string> = {
      amp: "&",
      lt: "<",
      gt: ">",
      apos: "'",
      nbsp: " ",
      copy: "©",
      reg: "®",
      euro: "€",
      cent: "¢",
      pound: "£",
      yen: "¥",
      sect: "§",
      deg: "°",
      plusmn: "±",
      sup2: "²",
      sup3: "³",
      micro: "µ",
      para: "¶",
      middot: "·",
      frac14: "¼",
      frac12: "½",
      frac34: "¾",
      iquest: "¿",
      times: "×",
      divide: "÷",
    };
    return entities[entity] ?? match;
  });
