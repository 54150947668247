"use client";

import dayjs from "dayjs";
import {
  EventCalendarIcon,
  LocationPinIcon,
  VerticalDotsMenuIcon,
} from "icons";
import { useState } from "react";
import { Button as RAButton } from "react-aria-components";
import {
  Heading,
  ImageWrapper,
  Link,
  LinkButton,
  Popover,
  Text,
} from "ui-components";

import { PLACEHOLDER_IMAGE } from "@/src/constants/images";
import { WpPostEntity } from "@/src/hooks/api/generated";
import { useNavigate } from "@/src/hooks/useNavigate";
import { extractTextAndTruncate, getMetaValue } from "@/src/utils/wordpress";
import { parseContent } from "@/utils/parseContent";
import { concatString } from "@/utils/string";

import { DeleteEventModal } from "./DeleteEventModal";

interface IEventCardWPProps {
  event: WpPostEntity;
  isWorkpride?: boolean;
  isEditable?: boolean;
  height?: number;
  refetch?: () => void;
}

export function EventCard({
  event,
  isWorkpride,
  isEditable = false,
  height = 230,
  refetch,
}: Readonly<IEventCardWPProps>): JSX.Element {
  const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] =
    useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { push } = useNavigate();

  const summary = getMetaValue(event, "summary");

  return (
    <>
      <DeleteEventModal
        eventId={event.ID}
        isOpen={isOpenDeleteConfirmationModal}
        onOpenChange={setIsOpenDeleteConfirmationModal}
        refetch={refetch}
      />
      <div
        key={event.ID}
        className={`!flex flex-col gap-y-2.5 px-2.5 max-w-full ${
          isWorkpride
            ? " px-0 bg-white rounded-3xl mx-2 pb-10 pl-0 pr-0 min-h-[500px]"
            : ""
        }`}
      >
        <Link
          decoration={false}
          hasHoverEffect={false}
          href={`/events/${event.post_name}`}
        >
          <ImageWrapper
            src={getMetaValue(event, "_cloudinary") || PLACEHOLDER_IMAGE}
            alt={parseContent(event.post_title)}
            width={439}
            height={height}
            className="rounded-t-xl mb-2.5 w-full object-cover object-top"
            style={{ height: !isWorkpride ? `${height}px` : "auto" }}
            priority
            unoptimized
          />
        </Link>
        <div
          className={`!flex flex-col gap-y-2.5 bg-white rounded-3xl ${
            isWorkpride ? "mx-3 px-2.5" : ""
          }`}
        >
          <div className="flex justify-between items-center">
            <Link
              decoration={false}
              hasHoverEffect={false}
              href={`/events/${event.post_name}`}
            >
              <Heading
                variant="h4"
                className={`!text-left line-clamp-2 ${
                  isWorkpride ? "h-14" : ""
                }`}
              >
                {concatString(parseContent(event.post_title), 150)}
              </Heading>
            </Link>
            {isEditable && (
              <Popover onOpenChange={setIsPopoverOpen} isOpen={isPopoverOpen}>
                <Popover.Trigger>
                  <VerticalDotsMenuIcon className="w-1 mr-2 cursor-pointer" />
                </Popover.Trigger>
                <Popover.Content
                  placement="bottom right"
                  className="p-4 rounded-3xl"
                >
                  <div className="w-full flex flex-col items-center justify-center gap-y-2.5 ">
                    <RAButton
                      className="outline-none group"
                      onPress={() => {
                        push(`/edit-event/${event.ID}`);
                      }}
                    >
                      <Text className="group-hover:text-primary">Edit</Text>
                    </RAButton>
                    <RAButton
                      className="outline-none group"
                      onPress={() => {
                        setIsOpenDeleteConfirmationModal(true);
                        setIsPopoverOpen(false);
                      }}
                    >
                      <Text className="group-hover:text-danger">Delete</Text>
                    </RAButton>
                  </div>
                </Popover.Content>
              </Popover>
            )}
          </div>

          <Link
            decoration={false}
            hasHoverEffect={false}
            href={`/events/${event.post_name}`}
            className="space-y-2.5"
          >
            <>
              {!!summary && (
                <p
                  className={`text-sm line-clamp-3 ${
                    isWorkpride ? "h-16" : ""
                  }`}
                >
                  {extractTextAndTruncate(summary, 300)}
                </p>
              )}
              {!summary && (height > 200 || isWorkpride) && (
                <div className="h-16" />
              )}
              {getMetaValue(event, "event_date") && (
                <p className="flex gap-x-1.5 items-center">
                  <EventCalendarIcon className="w-6 h-6" />
                  {dayjs(
                    getMetaValue(event, "event_date").split("T")[0],
                    "DDMMYYYY"
                  ).format("MMMM D, YYYY")}
                  &nbsp;at&nbsp;
                  {dayjs(
                    `2024-01-01T${getMetaValue(event, "event_time")}`
                  ).format("h:mm A")}
                  &nbsp;GMT
                </p>
              )}
              {getMetaValue(event, "event_location") && (
                <p className="flex gap-x-1.5 items-center">
                  <div>
                    <LocationPinIcon className="w-6 h-6" />
                  </div>
                  <div className="break-words overflow-wrap-anywhere">
                    {getMetaValue(event, "event_location")}
                  </div>
                </p>
              )}
            </>
          </Link>
          {isWorkpride && (
            <div className="flex flex-col items-center justify-center">
              <LinkButton
                className="!bg-[#050D5F] text-white !w-36 py-2 hover:text-white"
                href={`/events/${event.post_name}`}
              >
                Watch Now
              </LinkButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
